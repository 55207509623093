import Othercomponent from './components/Photography/othercomponent';
import Sidebar from './components/Sidebar/sidebar';
import './nature.css';

function Other(){
    return (
        <>
            <Othercomponent />
            <Sidebar />
        </>
    )
}
export default Other;