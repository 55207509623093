import Contactcontainer from './components/Contact/Contactcontainer';
import Sidebar from './components/Sidebar/sidebar';
// import Footer from './components/Footer/footer';
import './contact.css';


function Contact(){
    return (
        <>
            <Contactcontainer />
            <Sidebar />
            {/* <Footer /> */}
        </>
    )
}
export default Contact;