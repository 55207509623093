import Foodcomponent from './components/Photography/foodcomponent';
import Sidebar from './components/Sidebar/sidebar';
import './nature.css';

function Food(){
    return (
        <>
            <Foodcomponent />
            <Sidebar />
        </>
    )
}
export default Food;