import Tradition from './components/Photography/tradition';
import Sidebar from './components/Sidebar/sidebar';
import './city.css';

function Culture(){
    return (
        <>
            <Tradition />
            <Sidebar />
        </>
    )
}
export default Culture;