import Landscapecomponent from './components/Photography/landscapecomponent';
import Sidebar from './components/Sidebar/sidebar';
import './nature.css';

function Landscape(){
    return (
        <>
            <Landscapecomponent />
            <Sidebar />
        </>
    )
}
export default Landscape;