import Navbar from './components/NavBar/navbar';
// No need to import Dropdown here

function Navbar2(){
    return (
        <>
            <Navbar />
        </>
    )
}
export default Navbar2;
