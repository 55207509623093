import Natural from './components/Photography/natural';
import Sidebar from './components/Sidebar/sidebar';
import './nature.css';

function Nature(){
    return (
        <>
            <Natural />
            <Sidebar />
        </>
    )
}
export default Nature;