import Navbar2 from './Navbar2';
import Intro from './components/Intro/intro';
import Footer from './components/Footer/footer';
import './home.css';

export default function Home(){
    return (
        <>
            <Navbar2 />
            <Intro />
            <Footer />
        </>
    )
}

