import Country from './components/Photography/Country';
import Sidebar from './components/Sidebar/sidebar';
import './city.css';

function City(){
    return (
        <>
            <Country />
            <Sidebar />
        </>
    )
}
export default City;