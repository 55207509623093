import Sky from './components/Photography/sky';
import Sidebar from './components/Sidebar/sidebar';
import './flying.css';

function Flying(){
    return (
        <>
            <Sky />
            <Sidebar />
        </>
    )
}
export default Flying;